import React from "react";
import { graphql } from 'gatsby'
import VideoFrame from "../components/video-demo/video";
import { HelmetDatoCms } from "gatsby-source-datocms";
import Layout from "../layouts";

export default ({ data: { datoCmsWebinarVideo } }) => {
    return (
        <Layout>
            <HelmetDatoCms seo={datoCmsWebinarVideo.seoMetaTags} />
            <VideoFrame
                title={datoCmsWebinarVideo.title}
                subtitle={datoCmsWebinarVideo.subtitle}
                videoUrl={`http://vimeo.com/${datoCmsWebinarVideo.vimeoId}`}
            />
        </Layout>
    );
};

export const query = graphql`
query WebinarVideoQuery($slug: String!) {
    datoCmsWebinarVideo(slug: {eq: $slug}) {
      id
      title
      subtitle
      vimeoId
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
  }
`;
