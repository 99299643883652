import React from "react";
import ReactMarkdown from "react-markdown";
import SocialShare from "../../components/common/social-share";
import { createVideo, getSafe } from "../../utils/common";
//// DEVELOPMENT
// import $ from 'jquery';

export default ({ title, subtitle, videoUrl, displaySocialShare }) => {
    try {
        let $iframe = createVideo(videoUrl);
        setTimeout(() => {
            getSafe(() => $("#VideoWrapper").append($iframe));
        });
    } catch (error) { }
    return (
        <header className="main-header about">
            <div className="container">
                <div className="video-page-container">
                    <h1 className="h-fixedwidth">{title}</h1>
                    <div className="sm-spacing" />
                    <ReactMarkdown source={subtitle} escapeHtml={false} />
                    <div className="sm-spacing" />
                    {displaySocialShare !== undefined && (
                        <SocialShare id="" horizontal="true" />
                    )}
                    <div className="md-spacing" />
                    <div className="w-embed w-iframe w-script">
                        <div
                            id="VideoWrapper"
                            style={{ padding: "56.25% 0 0 0", position: "relative" }}
                        >                            
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
};
